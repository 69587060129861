<template>
    <v-card>
        <v-card-title class="text-h6 mb-5">Không thấy</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-text-field v-model="code" class="c-input-small" :label="$t('labels.sku')" dense outlined clearable
                        hide-details @keyup.enter="confirm"></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="cancel">
                {{ $t('labels.cancel') }}
            </v-btn>
            <v-btn color="green darken-1" text @click="confirm" :disabled="isDisabled">
                {{ $t('labels.confirm') }}
            </v-btn>
        </v-card-actions>

    </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
export default {
    name: "FindGoodsNotFound",
    data: () => ({
        code: null,
        isLoading: false
    }),
    computed: {
        isDisabled() {
            return !this.code
        }
    },
    methods: {
        cancel() {
            this.$emit('cancel', true)
        },
        refreshData() {
            this.$emit('refreshData')
        },
        async confirm() {
            if (this.isDisabled) {
                return false
            }
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true
            try {
                await httpClient.post('/find-goods/v1/employee-item-not-found', {
                    code: this.code,
                })
                this.isLoading = false
                this.$root.$emit('playSuccessAudio')
                this.refreshData()
                this.cancel()
            } catch (e) {
                this.code = null
                this.isLoading = false
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
                this.$root.$emit('playErrorAudio')
            }
        }
    }
}
</script>